<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <p class="ma-0 muted" v-if="showProcessTimeSpan">
          <strong>{{ $t('feedbacks.timespan') }}: </strong>
          {{ $d(new Date(process.startDate), 'date') }} &ndash;
          {{ $d(new Date(process.endDate), 'date') }}
        </p>
      </v-col>

      <v-col cols="12" md="6" v-if="processHasDialog(process.processType) || talkDate">
        <div class="ma-0 d-flex justify-md-end align-center">
          <template v-if="talkDate">
            <v-icon class="mr-1">mdi-calendar</v-icon>
            <strong class="d-none d-inline-md mr-1">
              {{ $t('feedbacks.talkDate') }}:
            </strong>
            {{ $d(Date.parse(talkDate), 'datetime') }}
          </template>

          <v-menu
            v-show="talkDateEditable"
            v-model="showDatetimePicker"
            :close-on-content-click="false"
            :nudge-left="showEditTalkdateButton ? 230 : 0"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template
              v-slot:activator="{ on: onMenu }"
              v-if="showAddTalkdateButton"
            >
              <v-btn small color="default" v-on="onMenu" min-width="250">
                <v-icon small class="mr-2">mdi-calendar</v-icon>
                {{ $t('feedbacks.setTalkDate') }}
              </v-btn>
            </template>

            <template
              v-slot:activator="{ on: onMenu }"
              v-else-if="showEditTalkdateButton"
            >
              <v-btn x-small fab text v-on="{ ...onMenu }">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>

            <date-time-picker
              ref="dateTimePicker"
              :date="talkDate"
              @selected="talkDateSelected"
            ></date-time-picker>
          </v-menu>

          <template v-if="talkDate">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  x-small
                  text
                  fab
                  class="px-0"
                  v-on="on"
                  @click="exportTalkDate"
                >
                  <v-icon small>mdi-calendar-export</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('feedbacks.exportTalkDate') }}</span>
            </v-tooltip>
          </template>
        </div>
      </v-col>
    </v-row>

    <template v-if="process.feedbacks">
      <feedback-item
        v-for="item in process.feedbacks"
        :key="`sub_feedback_${item.id}`"
        :item="item"
        :processType="process.processType"
      >
      </feedback-item>
    </template>

    <pdp-table
      v-if="process.pdpData"
      :pdpData="process.pdpData"
      readonly
    ></pdp-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import dateTimePicker from '@/components/datetime-picker.vue';
import feedbackItem from '@/components/feedbacks/feedback-item.vue';
import pdpTable from '@/components/feedbacks/pdp/pdp-table.vue';
import processMixins from '@/mixins/feedback-process.js';

export default {
  props: {
    process: {
      type: Object,
      required: true
    }
  },

  mixins: [processMixins],

  data: () => ({
    dialog: false,
    showDatetimePicker: false,
    talkDate: null,
    showAddTalkdateButton: false,
    showEditTalkdateButton: false,
    statusOptions: [],

    // Flag to display or hide the time span of the process.
    // This might need to be re-enabled via organization or
    // process settings in the future so I'll hide it behind a
    // flag instead of removing the markup.
    showProcessTimeSpan: false
  }),

  watch: {
    showDatetimePicker() {
      if (!this.showDatetimePicker) {
        this.$refs.dateTimePicker.reset();
      }
    }
  },

  computed: {
    ...mapGetters({
      getWorkflowSettings: 'workflowSettings'
    }),

    talkDateEditable() {
      return (
        this.processHasDialog(this.process.processType) &&
        !this.process.isFinalStatus &&
        (this.process.isRecipient || this.process.isDonor)
      );
    }
  },

  async mounted() {
    this.talkDate = this.process.talkDate;
    if (this.talkDate === null) {
      this.showAddTalkdateButton = this.talkDateEditable;
    } else {
      this.showEditTalkdateButton = this.talkDateEditable;
    }
  },

  methods: {
    ...mapActions({
      getCalendarData: 'feedbacks/exportTalkDate',
      saveTalkDate: 'feedbacks/saveTalkDate',
      getStatusOptions: 'feedbacks/listFeedbackStatusOptions'
    }),

    async exportTalkDate() {
      const data = await this.getCalendarData(this.process.id);
      const blob = new Blob([data.calendarFileContent], {
        type: 'text/calendar'
      });

      const fileUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.href = fileUrl;
      anchor.download = 'Kalender.ics';
      anchor.click();

      window.URL.revokeObjectURL(fileUrl);
    },

    getStatusOption(processId, statusId) {
      const option = this.statusOptions.find(
        (x) => x.processType === processId && x.statusId === statusId
      );
      if (option) {
        return this.localize(option.translations);
      }
      return '';
    },

    talkDateSelected(selectedDate) {
      this.showDatetimePicker = false;
      this.showAddTalkdateButton = false;
      this.showEditTalkdateButton = true;

      const isoTalkDate = selectedDate.toISOString();
      this.talkDate = isoTalkDate;

      this.saveTalkDate({
        processId: this.process.id,
        talkDateUtc: isoTalkDate
      });
    },

    processHasDialog(processType) {
      const workflowSettings = this.getWorkflowSettings;
      if (workflowSettings === undefined) {
        return false;
      }

      const processSettings = workflowSettings.find(
        (x) => x.processType === processType.toString()
      );

      if (processSettings === undefined) {
        return false;
      }

      return processSettings.hasDialog;
    }
  },

  components: {
    dateTimePicker,
    feedbackItem,
    pdpTable
  }
};
</script>
